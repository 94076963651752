import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-oberammergau-preview.jpg'
import presseImage4 from '@images/presse/Festl_Tour_Impressionen_4.jpg'
import presseImage5 from '@images/presse/Festl_Tour_Impressionen_5.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-oberammergau.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Oberammergau feiert bayerisch mit Live-Musik, lokalen Schmankerln und bester Stimmung!
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Die Alpin FM Festl Tour mit ERDINGER Brauhaus macht Halt in Oberammergau
                  </strong>
                </p>
                <p>
                  Erding, 11.07.2024<br />
                  Die Reise der Alpin FM Festl Tour mit ERDINGER Brauhaus geht nach einem erstklassigen Auftakt in
                  Bad Reichenhall weiter. Diesmal ist die zweite Station Oberammergau!
                </p>
                <p>
                  Am Sonntag, den 21. Juli, wird im Musikpavillon Oberammergau ab 14 Uhr zünftig gefeiert. Die
                  bayerischen Top-Bands Fenzl, Kopfeck, Oimara und Monobo Son spielen groß auf. Das ERDINGER
                  Brauhaus-Team, der Junggesell*innenverein Oberammergau und das Ammergauer Haus stehen
                  bereit, um die Gäste mit ERDINGER Brauhaus-Spezialitäten und alkoholfreien Getränken zu versorgen
                  – hier bleibt kein Wunsch offen! Auch um das leibliche Wohl kümmert sich der JGV Oberammergau
                  und verwöhnt die Gäste den gesamten Tag über mit herrlichen Grillschmankerln. Mit steigender
                  Stimmung am Abend wird der Musikpavillon dann zur Festivalzone. Jeder ist herzlich eingeladen
                  mitzufeiern – denn gemeinsam wird getanzt, gesungen und angestoßen. Der Eintritt ist frei. Alpin FM,
                  die Bands sowie die lokalen Partner und ERDINGER Brauhaus freuen sich auf zahlreiche Besucher!
                </p>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <ImageSource>Bildquelle: Alexander Schmidt / ERDINGER Weißbräu</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage4} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                  <ImageSource>Bildquelle: Alexander Schmidt / ERDINGER Weißbräu</ImageSource>
                </ContentRowImage>
                <ContentRowImage>
                  <img src={presseImage5} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                  <ImageSource>Bildquelle: Alexander Schmidt / ERDINGER Weißbräu</ImageSource>
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
